.container-div {
  padding: 100px 0;
  margin-right: 50px;
  width: 50%;
}

.parent-content-details {
  column-gap: 30px;
  flex-direction: row-reverse;
  align-items: start;
  justify-content: center;
}

.container-div .card-details {
  color: #121212;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: justify;
}

.container-div .card-details .title-details {
  margin-block: 30px;
  font-weight: bold;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.conternt-text-details {
  font-size: 18px;
  padding-inline: 30px;
  word-break: break-word;
  margin-bottom: 30px;
  line-height: 30px;
}

.parent-content-details .container-div .breadcrumb .breadcrumb-item {
  color: #121212;
  font-size: 19px;
  text-decoration: none;
}

.parent-content-details .container-div .breadcrumb .breadcrumb-item a {
  color: #ffba07;
  text-decoration: none;
}

.card-read-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding-block: 30px;
  padding-inline: 20px;
  border-radius: 5px;
  margin-top: 150px;
  position: sticky;
  top: 10px;
  width: 25%;
  margin-bottom: 30px;
}

.card-read-more .news-section-read-more {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding-inline: 10px;
}

.card-read-more .title-card-read-more {
  color: #ffba07;
  font-size: 25px;
  margin-bottom: 35px;
}

.card-read-more .news-card-read-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  color: #121212;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  row-gap: 20px;
}

.card-read-more .news-card-read-more img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.card-read-more .news-card-read-more h4 {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
}

@media (max-width: 1117px) {
  .parent-content-details {
    flex-direction: column !important;
    justify-content: center !important;
    padding-inline: 20px;
  }
  .card-read-more {
    width: 100%;
    margin-top: 100px;
    padding-inline: 20 !important;
  }

  .container-div {
    width: 100%;
    padding-bottom: 0;
  }
}

@media (max-width: 344px) {
  .parent-content-details {
    padding-inline: 0px;
  }
  .card-read-more .news-card-read-more {
    flex-direction: column;
    justify-content: center;
  }
  .card-read-more .news-card-read-more h4 {
    text-align: center;
  }
  .card-read-more .news-section-read-more {
    row-gap: 40px;
  }
  .conternt-text-details {
    padding-inline: 10px;
  }
  .breadcrumb {
    padding-inline: 10px;
  }
  .card-read-more .news-section-read-more .news-card-read-more .col-4 {
    width: auto !important;
  }
}
