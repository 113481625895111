.card-img-left {
  width: 100%;
  background: scroll center url("../../../public/Images/login.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.container-edite {
  box-shadow: 0 0 0 0 !important;
}

.form-floating > label {
  left: auto !important;
}

.container .row .card .card-body {
  width: 80%;
  padding: 60px 50px 60px 30px !important;
}

.button-login {
  background-color: #5b6680;
  border: 1px solid #5b6680;
  color: white;
}

.button-login:hover {
  background-color: #506eb3;
  border: 1px solid #506eb3;
}

.button-login:focus {
  background-color: #506eb3 !important;
  border: 1px solid #506eb3 !important;
  box-shadow: 1px 1px 1.25rem #506eb3 !important;
}

.input-login:focus {
  color: #121212;
  background-color: #fff;
  border: 1px solid #5b6680;
  outline: 0;
  box-shadow: 2px 2px 5px #5b6680;
}

.title-login {
  font-size: 40px !important;
  color: #495d8b;
}

.bg-login {
  background-color: #f8f9fa;
  width: 100vw;
}

*::selection {
  -webkit-text-fill-color: #fff;
}
