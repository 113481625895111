.parent-Table {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #121212;
  text-align: start;
  font-size: 16px;
}

table {
  table-layout: fixed;
  text-align: center;
  margin-top: 20px;
}
thead th {
  font-weight: bold;
  font-size: 20px;
}

thead {
  border-bottom: 2px solid #5b6680 !important;
}

.modal-body {
  text-align: start;
}

tr:not(:last-child) {
  border-bottom: 1px solid #5b6680 !important;
}

th,
td {
  padding-inline: 20px !important;
}

table tbody tr td img {
  margin-right: 22px;
}

.title-section {
  background: linear-gradient(to top, #151d31 7%, #5b6680 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 48px;
}

.title-section {
  margin-bottom: 32px;
  margin-top: 96px;
}

.image-add {
  margin-bottom: 26px;
  cursor: pointer;
}

.Delete-button,
.Edit-button {
  cursor: pointer;
  width: 40px;
}

.Model {
  color: #ffffff;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  z-index: 100;
  position: fixed;
  background-color: #121212;
  opacity: 97%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
}

.content-edite {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 88%;
}

table {
  background-color: #f5f5f5;
}

.button-event {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  width: 256px;
  height: 64px;
  background: #5b6680;
  margin-top: 32px;
  border: 1px solid #5b6680;
  border-radius: 3px;
}

.lable-form {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 32px;
}

.input-form {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #f5f5f5;
  width: 400px;
  height: 60px;
  background-color: #1d1d1d;
  border: 0;
  margin-bottom: 32px;
  border: 1px solid #5b6680;
}

.Model .Content-Model {
  width: 100%;
  height: 100% !important;
}

.Model .Content-Model textarea {
  height: 219px !important;
  padding-top: 10px;
  padding-right: 21px;
  resize: none;
  line-height: 1.5;
  padding-left: 5px;
}

.size {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-content {
  display: flex;
  justify-content: flex-end;
  margin-right: 283px;
}

td {
  font-size: 25px !important;
  color: rgb(0, 0, 0) !important;
  word-wrap: break-word;
  font-weight: 400;
}

.clouse-button {
  width: 50px;
  margin-top: 20px;
}

table {
  display: table;
}

.descktop-table {
  display: flex !important;
  flex-direction: column;
}

.mobile-table {
  display: none !important;
}

.card-content {
  margin: 20px auto;
  background-color: #ffffff;
  color: #121212;
  column-gap: 20px;
  border-radius: 5px;
}

.card-content img,
video {
  width: 100%;
  margin-bottom: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.descktop-table .card-content img,
.descktop-table .card-content video {
  margin-bottom: 0;
}

.descktop-table .card-content .image-card-des {
  width: 35%;
}

.card-content .word-news {
  padding-inline: 30px;
}

.descktop-table .card-content .word-news {
  padding-block: 20px;
  word-break: break-word;
}

.card-content .word-news h3 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}

.card-content .word-news p {
  margin-top: 20px;
  text-align: justify;
  font-size: 18px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions-icon-mobile {
  column-gap: 20px;
  margin-top: 30px;
}

.actions-icon-descktop {
  row-gap: 40px;
  margin-top: 30px;
}

.actions-icon-descktop img {
  width: 40px;
  margin-right: 20px;
}

.actions-icon-mobile img {
  width: 40px;
}

.descktop-table .card-content video {
  height: 400px !important;
}

@media (max-width: 992px) {
  .descktop-table {
    display: none !important;
  }
  .mobile-table {
    display: flex !important;
    flex-direction: column;
  }
  .input-form {
    width: 80% !important;
  }
  .button-content {
    margin-right: 0 !important;
  }
}

@media (max-width: 1400px) {
  .descktop-table .card-content video {
    height: 315px !important;
  }
}
