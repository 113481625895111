.parent-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 94px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.headtop.scrolled {
  position: sticky;
  top: -8px;
  z-index: 10;
  background-color: #f5f5f5e3;
  padding-block: 25px;
}

.parent-nav.reamve-margin {
  margin-top: 0 !important;
  padding-block: 20px;
}

.Icons,
.logout {
  font-size: 24px;
  margin-left: 224px;
  color: #5b6680;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  display: flex;
  gap: 25px;
}

.Icons-mobile {
  font-size: 24px;
  margin-left: 224px;
  color: #5b6680;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  display: flex;
  gap: 25px;
  display: none;
}

.nav-component {
  color: #5b6680;
  margin-right: 300px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.list {
  list-style: none;
  display: flex;
  align-items: center;
  margin-right: 240px;
}

.list .link-nav {
  margin-right: 32px;
  position: relative;
  text-decoration: none;
  color: #5b6680;
  animation: border-image 2s ease-in-out;
}

.nav-component {
  margin-right: 0;
  text-align: center;
}

.navbar-toggler-icon {
  background-image: url("../../../public/bars-solid.svg") !important;
}

.parent-nav .navbar-toggler {
  border-radius: 5px;
  padding: 10px;
}

.Icons img:hover {
  filter: brightness(0);
}

.Icons-footer {
  display: none !important;
}

@media (max-width: 1399px) {
  .parent-nav {
    justify-content: space-around;
  }

  .Icons {
    margin-left: 0;
    margin-right: 20px;
  }
  .list {
    margin-right: 0;
    margin-top: 20px;
    padding-right: 0;
  }

  .link-nav {
    margin-bottom: 10px;
    font-size: 25px;
  }
  .list .link-nav {
    margin-right: 0;
  }

  .row .Edite-cart .image-show-descktop {
    height: 500px;
  }

  .content-discrption {
    font-size: 24px;
  }

  .cv-section {
    column-gap: 60px;
  }
}

@media (max-width: 1120px) {
  .headtop.scrolled {
    padding: 0 !important;
  }

  .Icons-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0;
  }
  .Icons-mobile img {
    width: 250px;
    text-align: start;
  }
  .Icons-footer {
    display: flex !important;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 34px;
    margin-bottom: 30px;
  }

  .parent-nav {
    margin-top: 40px;
  }
}

.parent-nav .active {
  color: #ffba07 !important;
}

.list .link-nav::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-bottom: 2px solid #ffba07;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.list .link-nav:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
