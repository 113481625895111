.row {
  margin-right: 0 !important;
}

.row .Edite-cart .image-show-descktop {
  margin-right: 220px;
}

.Edite-cart {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: end;
  color: white;
  flex-direction: row-reverse;
  margin-top: 100px;
  margin-bottom: 100px;
}

.title-head {
  margin-right: 225px;
}

.content-discrption {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-end;
  text-align: end;
  margin-top: 20px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 35.5px;
  color: #222230;
}

.buttons-contener {
  margin-top: 50px;
}

.call-me-button {
  padding: 6px 18px;
  margin-right: 21px;
  background-color: transparent;
  border: 2px solid #ebb033;
  color: #ffba07;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.call-me-button:hover {
  color: #5b6680;
  border: 2px solid #5b6680;
}

.info-button {
  padding: 6px 18px;
  background-color: #5b6680;
  border: 1px solid #5b6680;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.info-button:hover {
  color: #222230;
}
.cv-section {
  display: flex;
  column-gap: 128px;
  justify-content: center;
  align-items: center;
}

.content-cv-section {
  color: #222230;
  width: 50%;
}

.content-cv-section .title-cv-section {
  font-size: 52px;
  font-weight: bold;
}

.content-cv-section .descrption-cv-section {
  color: #222230;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  width: 80%;
  line-height: 30px;
  margin-bottom: 40px;
  text-align: justify;
}

.content-cv-section .list-cv-section {
  font-size: 20px;
  font-weight: 600;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 20px;
  padding-right: 0;
  line-height: 30px;
}

.content-cv-section .list-cv-section li {
  display: flex;
  align-items: center;
}

.content-cv-section .list-cv-section .icons {
  margin-left: 24px;
}

.newspaper-section {
  padding-inline: 220px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: center;
  row-gap: 20px;
  margin-top: 100px;
}

.title-newspaper-section {
  color: #ebb033;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 18px;
}

.list-newspaper-section {
  display: flex;
  flex-direction: row;
  column-gap: 31px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.sub-title-newspaper-section {
  font-size: 54px;
  font-weight: bold;
  color: #121212;
  margin-bottom: 56px;
}

.newspaper-section .card-newspaper-section {
  color: #121212;
  background-color: white;
  width: 460px !important;
  height: 552px;
  padding-bottom: 40px;
}

.newspaper-section .card-newspaper-section img {
  width: 100%;
  margin-bottom: 55px;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.newspaper-section .card-newspaper-section .tilte-news-inercard {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 22px;
  margin-right: 26px;
  padding-left: 10px;
  word-break: break-word;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  transition: height 0.3s ease;
}

.newspaper-section .card-newspaper-section .descrption-news-inercard {
  color: #222230;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  padding-inline: 26px 41px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: height 0.3s ease;
  word-break: break-word;
  margin-bottom: 20px;
}

.newspaper-section .card-newspaper-section .read-more-buttons {
  background-color: transparent;
  border: 0;
  color: #222230;
  font-size: 16px;
  font-weight: bold;
  margin-right: 26px;
  margin-bottom: 57px;
  margin-top: 18px;
  text-decoration: none;
}

.call-us-section {
  padding-inline: 220px;
  margin-bottom: 515px;
  height: 346px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.call-us-section .descrotion-call-us-section .title-call-us-section {
  font-size: 32px;
  font-weight: 600;
  color: #ebb033;
  margin-bottom: 7px;
}

.call-us-section .descrotion-call-us-section .sub-title-call-us-section {
  font-size: 54px;
  font-weight: bold;
  color: #222230;
  margin-bottom: 22px;
}

.call-us-section .descrotion-call-us-section .explan-call-us-section {
  color: #5b6680;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  margin-bottom: 31px;
}

.call-us-section .descrotion-call-us-section .mail-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.call-us-section .descrotion-call-us-section .title-mail-section {
  margin-inline: 20px 33px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  color: #121212;
}

.call-us-section .descrotion-call-us-section .perosnal-mail-section {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: #5b6680;
}

.call-us-section .form-call-us {
  color: #5b6680;
  margin-right: 80px;
}

.call-us-section .form-call-us form {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 24px;
  color: #5b6680;
}

.call-us-section .info-button-submit {
  background-color: #ffba07;
  color: #222230;
  border: 1px solid #ebb033;
  width: 131px;
  height: 41px;
  padding: 0 !important;
}

.call-us-section .form-call-us label {
  margin-bottom: 2px;
}

.call-us-section .form-call-us input {
  margin-bottom: 41px;
  border: 1px solid #ffba07;
  width: 100%;
  padding-block: 10px;
  padding-right: 21px;
  background-color: transparent;
  color: #5b6680;
  font-family: sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.call-us-section .form-call-us textarea {
  height: 119px;
  padding-top: 10px;
  padding-right: 21px;
  border: 1px solid #ffba07;
  width: 100%;
  background-color: transparent;
  color: #5b6680;
  resize: none;
  line-height: 1.5;
  padding-left: 5px;
  margin-bottom: 41px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.call-us-section .form-call-us textarea::placeholder {
  color: #5b6680;
  font-size: 15.5px;
  opacity: 0.7;
}

p {
  margin: 0;
}

.footer-section {
  width: 100%;
  height: 108px;
  margin-top: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  background-color: #ffba07;
  color: #fff;
  column-gap: 10px;
}

.carousel-control-next,
.carousel-control-prev {
  height: 550px !important;
}

.weather-section {
  padding-inline: 220px;
  margin-bottom: 100px;
}

#ww_55e0cd3078fa8 .ww_col2 .ww_child:nth-child(odd) {
  background-color: #f5f5f5 !important;
}

.content-carousel {
  margin-bottom: 50px;
  margin-top: 100px;
  padding-inline: 120px;
}

/* slider */

@import url("https://fonts.googleapis.com/css?family=Roboto:900");

.custom-carousel {
  height: 600px;
  width: 55%;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding: 0;
  list-style: none;
}

.custom-carousel__controls,
.custom-carousel__activator {
  display: none;
}

.custom-carousel__activator:nth-of-type(1):checked ~ .custom-carousel__track {
  transform: translateX(0%);
}
.custom-carousel__activator:nth-of-type(1):checked
  ~ .custom-carousel__slide:nth-of-type(1) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}

.custom-carousel__activator:nth-of-type(1):checked
  ~ .custom-carousel__controls:nth-of-type(1) {
  display: block;
  opacity: 1;
}
.custom-carousel__activator:nth-of-type(1):checked
  ~ .custom-carousel__indicators
  .custom-carousel__indicator:nth-of-type(1) {
  opacity: 1;
}

.custom-carousel__activator:nth-of-type(2):checked ~ .custom-carousel__track {
  transform: translateX(-100%);
}
.custom-carousel__activator:nth-of-type(2):checked
  ~ .custom-carousel__slide:nth-of-type(2) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}

.custom-carousel__activator:nth-of-type(2):checked
  ~ .custom-carousel__controls:nth-of-type(2) {
  display: block;
  opacity: 1;
}
.custom-carousel__activator:nth-of-type(2):checked
  ~ .custom-carousel__indicators
  .custom-carousel__indicator:nth-of-type(2) {
  opacity: 1;
}

.custom-carousel__activator:nth-of-type(3):checked ~ .custom-carousel__track {
  transform: translateX(-300%);
}
.custom-carousel__activator:nth-of-type(3):checked
  ~ .custom-carousel__slide:nth-of-type(3) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}

.custom-carousel__activator:nth-of-type(3):checked
  ~ .custom-carousel__controls:nth-of-type(3) {
  display: block;
  opacity: 1;
}
.custom-carousel__activator:nth-of-type(3):checked
  ~ .custom-carousel__indicators
  .custom-carousel__indicator:nth-of-type(3) {
  opacity: 1;
}

.custom-carousel__activator:nth-of-type(4):checked ~ .custom-carousel__track {
  transform: translateX(-400%);
}
.custom-carousel__activator:nth-of-type(4):checked
  ~ .custom-carousel__slide:nth-of-type(4) {
  transition: opacity 0.5s, transform 0.5s;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transform: scale(1);
}

.custom-carousel__activator:nth-of-type(4):checked
  ~ .custom-carousel__controls:nth-of-type(4) {
  display: block;
  opacity: 1;
}
.custom-carousel__activator:nth-of-type(4):checked
  ~ .custom-carousel__indicators
  .custom-carousel__indicator:nth-of-type(4) {
  opacity: 1;
}

.custom-carousel__control {
  height: 20px;
  width: 20px;
  margin-top: -15px;
  top: 50%;
  position: absolute;
  display: block;
  cursor: pointer;
  border-width: 5px 5px 0 0;
  border-style: solid;
  border-color: #121212;
  opacity: 0.35;
  outline: 0;
  z-index: 3;
}

.custom-carousel__control:hover {
  opacity: 1;
}
.custom-carousel__control--backward {
  left: 10px;
  transform: rotate(-135deg);
}
.custom-carousel__control--forward {
  right: 10px;
  transform: rotate(45deg);
}

.custom-carousel__indicators {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.custom-carousel__indicator {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: inline-block;
  z-index: 2;
  cursor: pointer;
  opacity: 0.35;
  margin: 0 2.5px;
}

.custom-carousel__indicator:hover {
  opacity: 0.75;
}

.custom-carousel__slide {
  height: 100%;
  position: absolute;
  overflow-y: auto;
  opacity: 0;
}

.custom-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 100px;
}

.custom-carousel__slide:nth-of-type(1),
.custom-carousel--thumb
  .custom-carousel__indicators
  .custom-carousel__indicator:nth-of-type(1) {
  background-size: cover;
  background-position: center;
}

.custom-carousel__slide:nth-of-type(2),
.custom-carousel--thumb
  .custom-carousel__indicators
  .custom-carousel__indicator:nth-of-type(2) {
  background-size: cover;
  background-position: center;
}

.custom-carousel__slide:nth-of-type(3),
.custom-carousel--thumb
  .custom-carousel__indicators
  .custom-carousel__indicator:nth-of-type(3) {
  background-size: cover;
  background-position: center;
}

.custom-carousel__slide:nth-of-type(4),
.custom-carousel--thumb
  .custom-carousel__indicators
  .custom-carousel__indicator:nth-of-type(4) {
  background-size: cover;
  background-position: center;
}

.custom-carousel__indicators {
  display: none;
}

@media (max-width: 596px) {
  .call-us-section .descrotion-call-us-section .sub-title-call-us-section {
    font-size: 36px;
  }

  .carousel__slides {
    height: 350px !important;
  }
}

@media (max-width: 1759px) {
  .image-images-section {
    width: 300px !important;
  }

  .carousel__slides {
    height: 600px;
  }

  .carousel__thumbnails {
    label {
      height: 100px;
    }
  }

  .conternt-image-figure {
    width: 70% !important;
  }

  .content-cv-section .list-cv-section {
    width: 88%;
  }
  .content-cv-section .descrption-cv-section {
    width: 85%;
  }

  .row .Edite-cart .image-show-descktop {
    margin-right: 150px;
  }

  .title-head {
    margin-right: 128px;
  }
}

@media (max-width: 1027px) {
  section {
    padding: 0 50px;
  }
}

@media (max-width: 1088px) {
  .call-us-section {
    flex-direction: column;
    align-items: center;
    padding-inline: 0;
    height: auto;
    margin-bottom: 64px;
    row-gap: 50px;
  }
  .call-us-section .form-call-us {
    margin-right: 0;
    width: 57%;
  }
  .footer-section {
    margin-top: 0;
  }
  .footer-section h3 {
    font-size: 18px;
  }
  .sub-title-newspaper-section {
    font-size: 50px;
  }

  .weather-section {
    height: 220px;
  }
}

@media (max-width: 1120px) {
  .parent-nav {
    margin-top: 30px;
  }

  .row {
    margin-bottom: 100px !important;
  }

  .row .Edite-cart {
    margin-block: 70px 0px;
  }

  .cv-section {
    flex-direction: column;
  }

  .call-us-section {
    flex-direction: column;
    align-items: center;
    padding-inline: 0;
    height: auto;
    margin-bottom: 64px;
    row-gap: 50px;
  }

  .call-us-section .form-call-us {
    margin-right: 0;
  }

  .cv-section .image-cv {
    margin-bottom: 37px;
  }

  .content-cv-section {
    width: 90%;
    margin-right: 128px;
  }

  .content-cv-section .title-cv-section {
    font-size: 32px;
  }

  .sub-title-newspaper-section {
    font-size: 44px !important;
  }

  .content-cv-section .descrption-cv-section,
  .content-cv-section .list-cv-section {
    font-size: 18px;
  }

  .newspaper-section {
    padding-inline: 128px;
  }

  .list-newspaper-section {
    justify-content: center;
  }

  .call-us-section .descrotion-call-us-section .title-call-us-section {
    font-size: 24px;
  }

  .call-us-section .descrotion-call-us-section .sub-title-call-us-section {
    font-size: 36px;
  }

  .call-us-section .descrotion-call-us-section .explan-call-us-section,
  .call-us-section .descrotion-call-us-section .title-mail-section,
  .call-us-section .descrotion-call-us-section .perosnal-mail-section,
  .call-us-section .form-call-us form {
    font-size: 18px;
  }

  .footer-section {
    font-size: 20px !important;
  }

  .call-us-section .descrotion-call-us-section .mail-section {
    flex-direction: column;
    align-items: start;
  }

  .call-us-section .descrotion-call-us-section .title-mail-section {
    margin-inline: 0;
  }

  .row .Edite-cart .image-show-descktop {
    width: 100%;
    height: 601px;
    margin-right: 0;
    margin-left: 0;
  }

  .newspaper-section .card-newspaper-section {
    height: auto !important;
  }

  .newspaper-section .card-newspaper-section img {
    height: auto;
  }

  .Edite-cart {
    background-image: none !important;
    flex-direction: column;
    justify-content: center;
    height: auto;
    top: 20px;
  }

  .Edite-cart .title-head {
    width: 100%;
  }

  .Edite-cart .title-head img {
    width: 100%;
  }

  .row {
    padding-right: 0 !important;
    margin-left: 0 !important;
  }

  .content-discrption {
    margin-right: 50px;
    margin-top: 37px;
  }

  .row .Edite-cart .title-head img {
    display: none !important;
  }

  .custom-carousel__indicators {
    display: flex;
    justify-content: center;
  }

  .custom-carousel {
    width: 70%;
    height: 650px;
  }

  .call-us-section .col-7,
  .call-us-section .col-5 {
    width: 100% !important;
  }

  .weather-section,
  .call-us-section {
    padding-inline: 128px;
  }
}

.news-descktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 31px;
  row-gap: 30px;
}

.news-mobile {
  display: none;
}

@media (max-width: 1206px) {
  .news-descktop {
    display: none !important;
  }

  .carousel__slides {
    height: 500px;
  }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: flex;
    justify-content: center;
  }

  .news-mobile {
    display: block !important;
  }

  .newspaper-section .card-newspaper-section .read-more-buttons {
    margin-right: 0;
  }

  .newspaper-section .card-newspaper-section img {
    margin-bottom: 75px;
  }

  .newspaper-section .card-newspaper-section {
    text-align: center;
  }

  .carousel-control-next,
  .carousel-control-prev {
    top: 60px !important;
  }
}

*::selection {
  background-color: #5b6680;
  color: #121212;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 25px !important;
  filter: invert(1);
}

@media (max-width: 900px) {
  .custom-carousel {
    height: 550px;
  }
}

@media (max-width: 698px) {
  .call-us-section .descrotion-call-us-section .mail-section {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }

  .custom-carousel-container {
    margin-block: 0;
  }

  .row .Edite-cart .image-show-descktop {
    height: 450px !important;
  }

  .Edite-cart .title-head {
    margin-right: 0;
  }

  .content-discrption {
    align-content: center !important;
    align-items: center !important;
    margin-right: 0;
    text-align: center;
  }

  .conternt-image-figure {
    width: 100% !important;
  }

  section {
    padding: 0 !important;
  }

  .weather-section {
    padding: 0;
    padding-inline: 10px;
    margin-top: 100px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    height: 480px !important;
  }

  .call-us-section .descrotion-call-us-section .title-mail-section {
    margin-inline: 0;
  }
  .newspaper-section {
    align-items: center;
    padding-inline: 0;
    margin-bottom: 0 !important;
  }
  .newspaper-section .card-newspaper-section {
    width: 100% !important;
    height: auto;
  }
  .sub-title-newspaper-section {
    font-size: 36px !important;
    margin-bottom: 46px;
    padding-inline: 15px;
  }

  .carousel {
    width: 100%;
  }

  .carousel__thumbnails,
  .content-cv-section .list-cv-section,
  .content-cv-section .descrption-cv-section {
    width: 100%;
  }

  .custom-carousel {
    width: 100%;
    height: 600px;
  }

  .content-cv-section .title-cv-section {
    text-align: center;
  }

  .newspaper-section {
    margin-top: 70px;
  }

  .content-cv-section {
    margin-right: 0;
  }

  .call-us-section {
    padding-inline: 0px;
  }

  .call-us-section .col-7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .call-us-section .col-7,
  .call-us-section .col-5 {
    width: 90% !important;
  }
}

@media (max-width: 598px) {
  .carousel__slides {
    height: 455px;
    margin-top: 80px;
  }

  .carousel__thumbnails .lable-image {
    height: 100%;
    width: 100px;
    overflow: hidden;
  }

  .image-images-section {
    height: auto !important;
  }

  .custom-carousel {
    width: 100%;
    height: 550px;
  }

  .cv-section .image-cv {
    width: 90%;
  }

  .cv-section .image-cv img {
    width: 100% !important;
    height: auto !important;
  }

  .row .Edite-cart .image-show-descktop {
    height: 421px !important;
  }
}

@media (max-width: 500px) {
  .custom-carousel {
    height: 500px;
  }

  .content-discrption {
    margin-right: 20px;
    font-size: 20px;
  }

  .row .Edite-cart .image-show-descktop {
    height: 500px;
  }

  .content-cv-section {
    margin-top: 0;
  }
}

@media (max-width: 430px) {
  .custom-carousel {
    height: 430px;
  }
}

@media (max-width: 336px) {
  .custom-carousel {
    height: 370px;
  }
  .newspaper-section .card-newspaper-section img {
    margin-bottom: 40px;
  }

  .newspaper-section .card-newspaper-section {
    height: 430px !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    top: -25px !important;
  }
}

@media (max-width: 400px) {
  .footer-section {
    font-size: 16px !important;
  }

  .sub-title-newspaper-section,
  .call-us-section .descrotion-call-us-section .sub-title-call-us-section {
    font-size: 32px !important;
  }
}

@media (max-width: 300px) {
  .content-discrption {
    margin-right: 0;
  }
}

.cv-section .image-cv {
  position: relative;
  height: 100%;
  border-radius: 50%;
  background-color: #a9a9a936;
  overflow: hidden;
}

.cv-section .image-cv img {
  width: 100%;
  position: relative;
  top: 20px;
  left: 15px;
}

@media (max-width: 460px) {
  .carousel-control-next,
  .carousel-control-prev {
    top: 30px !important;
  }

  .carousel__slides {
    height: 250px !important;
  }
}
